<template>
  <div>
    <v-card width="500" class="mx-auto">
      <v-card-title>
        <v-icon medium>person</v-icon>
        <span class="headline">
          <b>تغيير كلمة السر</b>
        </span>
      </v-card-title>
      <v-divider></v-divider>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout row wrap>
              <v-flex xs12 sm12>
                <v-text-field
                  v-if="showOldPassword"
                  label="كلمة السر القديمة"
                  placeholder="كلمة السر القديمة"
                  type="password"
                  v-model="oldPassword"
                  :rules="oldPasswordRules"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12>
                <v-text-field
                  label="كلمة السر الجديدة"
                  placeholder="كلمة السر الجديدة"
                  type="password"
                  v-model="newPassword"
                  :rules="passwordRules"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12>
                <v-text-field
                  label="تأكيد كلمة السر"
                  type="password"
                  placeholder="تأكيد كلمة السر"
                  v-model="confirmPassword"
                  :rules="passwordConfirmRules"
                  required
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="save()"
            style="background:#5867dd;color:white;margin-left:5px;"
            dark
          >
            <b>تحديث</b>
          </v-btn>
          <v-btn @click="$router.go(-1)" style="background:gray;" dark>
            <b>رجوع</b>
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showOldPassword: false,
      valid: true,
      oldPasswordRules: [v => !!v || "تأكيد كلمة السر مطلوب"],
      passwordRules: [
        v => !!v || "كلمة السر مطلوبة",
        //v=> (?!^.*[A-Z]{2,}.*$)^[A-Za-z]*$.test(v)||'كلمة المرور يجب أن تحوي أرقام, أحرف صغيرة, كبيرة ورموز',
        v => (v && v.length >= 8) || "كلمة المرور يجب أن لا تقل عن 8 محارف"
      ],
      passwordConfirmRules: [
        v => !!v || "تأكيد كلمة السر مطلوب",
        v => v == this.newPassword || "كلمة السر وتأكيد كلمة السر غير متطابقان"
      ],
      oldPassword: "",
      newPassword: "",
      confirmPassword: ""
    };
  },
  created() {
    this.showOldPassword = this.$route.params.Id == null ? true : false;
  },
  methods: {
    save() {
      if (this.$refs.form.validate()) {
        let url = "Auth/ChangePassword?id=" + this.$route.params.Id;
        let data = {
          newPass: this.newPassword,
          confirmPass: this.confirmPassword,
          token: ""
        };
        if (this.showOldPassword) {
          let user = JSON.parse(window.localStorage.getItem("authUser"));
          url = "Auth/ChangeMyPassword?id=" + user.id;
          data = {
            currentPass: this.oldPassword,
            newPass: this.newPassword,
            confirmPass: this.confirmPassword
          };
        }
        this.ApiService.post(url, data)
          .then(() => {
            this.hel.showSnackMsg("تمت العملية بنجاح", "#58dd5b", 2000);
            this.$router.go(-1);
          })
          .catch(() => {
            this.hel.showSnackMsg("فشلت العملية", "#af1616", 2000);
          });
      }
    }
  }
};
</script>
